<template>
    <div>
        <v-container v-if="!$store.getters.getIsReferee">
            <h1 class="display-1 mb-10">Connexion arbitrage</h1>
            <v-alert v-if="error"
                border="left"
                dismissible
                elevation="6"
                type="error"
                @click="error = false"
            >
                Mot de passe incorrect.
            </v-alert>
            <v-text-field
                v-model="pass"
                placeholder="Mot de passe arbitres"
                type="password"
                outlined
            />
            <v-btn
                class="ref-button yellow darken-2"
                @click="handleRefLogin"
            >Connexion</v-btn>
        </v-container>
        <v-container v-if="$store.getters.getIsReferee">
            <h1 class="display-1 mb-10">Menu arbitrage</h1>
            <v-btn
                class="ref-button yellow darken-2"
                @click="handleRefLogout"
            >Déconnexion</v-btn>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'RefLogin',
    data() {
        return {
            correctPass: '4157',
            pass: null,
            error: false,
        }
    },
    methods: {
        handleRefLogin() {
            if (this.pass === this.correctPass) {
                this.$store.commit('setReferee', true);
            } else {
                this.error = true;
            }
        },

        handleRefLogout() {
            this.$store.commit('setReferee', false);
        }
    }
}
</script>

<style>

</style>